%molecule-grid--list01{
    display: block;
    @include RWD(desktop){
        padding: 0 50px;
    }

    .grid-item{
        @extend %atom-grid--list01;

        // Separator
        & + .grid-item {
            margin-top: 40px;
            &:before{
                content: '';
                position: absolute;
                top: 0; left: 50%;
                width: calc(100% + 100px); 
                transform: translateX(-50%);
                height: 1px;
                background-color: #f6f6f6;
                @include RWD(mobile){
                    width: 100%;
                }
            }
        }

        // Coupdecoeur #505d6d
        &.coup-de-coeur{
            position: relative;
            &:after{
                content: '';
                display: block;
                position: absolute;
                left: -50px; 
                right: -50px;
                top: -0px;
                bottom: -40px;
                height: auto; width: auto;
                background-color: $third-color;
                z-index: 1;
                @include RWD(mobile){
                    left: -20px;
                    right: -20px;
                }
            }
            & > * {
                position: relative;
                z-index: 2;
            }
            .item-content{
                .item-coup-de-coeur{
                    color: $core-color;
                }
                .item-date{
                    color: $core-color;
                }
                .item-title{
                    color: $core-color;
                }
                .item-categories{
                    color: $core-color;
                    &:before{
                        background-image: url( svg-bg-uri($svg-label, $core-color) );
                    }
                }
            }
            .item-infos{
                &:before{
                    background-color: #FFFFFF;
                }
                .item-geoloc{
                    color: $core-color;
                    &:before{
                        background-image: url( svg-bg-uri($svg-marker, $core-color) );
                    }
                    &:after{
                        background-color: #FFFFFF;
                    }
                }
                .item-misc{
                    color: $core-color;
                    &:before{
                        background-image: url( svg-bg-uri($svg-plus, $core-color) );
                    }
                    span:after{
                        background-color: $core-color;
                    }
                }
            }
        }
    }
}