%bone-form-structure{
    $gutter: 30;

    .widget{
        display: block;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 35px;

        & + .comment-field{
            margin-top: -25px;
        }

        .title{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
        }
        .content{
            display: flex;
            flex-direction: column;
            position: relative;

            .field-suffix{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
    }
    

    
    .hint{
        width: 100%;
        // margin-left: calc(17% + #{$gutter / 2}px);
    }

    .buttons.submit{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row-reverse;

        > * {
            margin-left: 20px;
        }

        @include RWD(mobile){
            // flex-direction: column;
            // align-items: flex-end;
            flex-wrap: wrap;
            align-items: center;

            & > *{
                margin-left: 0;

                & + * {
                    margin-top: 20px
                }
            }
        }
        .previous-button{
            order: 3;
            margin-right: auto;
            margin-left: 0;
            @include RWD(mobile){
                margin: 0;
                order: 3; 
                width: 50%;

                .content{
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                }

                button{
                    font-size: 0;
                    .btn-text{
                        font-size: 0;
                    }
                    &:after{
                        content: 'Prec';
                        font-size: 1.4rem;
                        font-size: 1.4rem;
                        color: #31455d;
                        font-family: "MontSerrat", arial;
                        text-transform: uppercase;
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .cancel-button{
            order: 2;
            @include RWD(mobile){
                margin: 0;
                margin-bottom: 10px;
                order: 1;
                width: 100%;
                .content{
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
        }
        .submit-button{
            order: 1;
            @include RWD(mobile){
                margin: 0;
                margin-bottom: 0px;
                width: 50%;
                order: 2;
                .content{
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: row;
                }
                button{
                    font-size: 0;
                    .btn-text{
                        font-size: 0;
                    }
                    &:before{
                        content: 'Suiv';
                        font-size: 1.4rem;
                        color: #31455d;
                        font-family: "MontSerrat", arial;
                        text-transform: uppercase;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }


    @include RWD(mobile){
        & > .widget{
            display: block;
            .title{
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
                display: block;
            }
            .content{
                width: 100%;
                margin-left: 0;
            }
        }
    }
}