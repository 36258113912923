%organism-header{
    width: 100%;
    height: auto;
    z-index: 200 !important;
    transition: all 0.3s;
    position: relative;
    
    // Nav top
    .nav-top{
        background: #3a3a3a;
        z-index: 2;
        width: 100%;
        position: relative;
        transition: all 0.3s;
        @include RWD(desktop){
            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: calc(50vw - #{$container-width/2}px + 30px);
                background-color: #2d2d2d;
                height: 100%;
            }            
        }
        .custom-container{
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;
            z-index: 2;

            @include RWD(tablet){
                padding: 0;
                padding-right: 0;
            }
            @include RWD(mobile){
                flex-wrap: wrap;
            }
        }
        .scrolled-logo{
            height: 48px;
            width: 170px;
            fill: #FFFFFF;
            @include RWD(tablet){
                margin-left: 40px;
                width: 160px;
            }
            @include RWD(mobile){
                margin-left: 20px;
                display: block;
                width: 150px;
                height: auto;
            }
        }
        .fill{
            flex: 1;
        }
        .nav-btn{
            padding: 0 25px;
            font-size: 1.7rem;
            height: 45px;
            @include RWD(mobile){
                padding: 0 10px;
            }

            .flexbox{
                display: flex;
                align-items: center;
                height: 100%;
            }
            .text{
                line-height: 1;
                color: #FFFFFF;
                text-transform: uppercase;
                font-family: $montserrat;
                font-weight: 700;
                font-size: 1.7rem;
                position: relative;
                &:after{
                    @extend %animation-hoverBar-in;
                    background-color: #FFFFFF;
                }
            }
            .picto{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 15px;
            }
            &:hover, &:focus{
                text-decoration: none;
                .text{
                    &:after{
                        @extend %animation-hoverBar-out;
                    }
                }
            }

            &.nav-contact{
                .picto{
                    background-image: url( svg-bg-uri($svg-mail, #FFFFFF) );
                }
                margin-right: 25px;
                @include RWD(mobile){
                    margin-right: 10px;
                    .text{
                        display: none;
                    }
                    .picto{
                        margin: 0;
                    }
                }
            }
            &.nav-account{
                padding-left: 50px;
                padding-right: 10px;
                .picto{
                    background-image: url( svg-bg-uri($svg-account, #FFFFFF) );                    
                }
                @include RWD(mobile){
                    padding-left: 20px;
                }
            }
            &.nav-logout{
                padding-left: 10px;
                padding-right: 10px;;
                background-color: #2d2d2d;
                .picto{
                    background-image: url( svg-bg-uri($svg-close, #717171) );
                }
                &:hover, &:focus{
                    .picto{
                        background-image: url( svg-bg-uri($svg-close, #ffffff) );
                    }
                }
                @include RWD(mobile){
                    margin-right: 10px;
                    .picto{
                        margin-right: 0;
                    }
                }
            }
        }
        .account-wrapper{
            background-color: #2d2d2d;
            display: flex;
            height: 100%;
            @include RWD(mobile){
                justify-content: space-between;
                width: 100%;
                flex-shrink: 0;
            }
        }
    }
} 