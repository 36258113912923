%organism-rte{
    // Clear paragraphs drupal
    .field-item{
        clear: both;
    }

    // Inlines
    @extend %molecule-inlines-rte;

    /* Headings */
    @extend %molecule-headings-rte;

    /* Inlines */
    p{
        margin-bottom: $margin-p;
    }

    /* Images */
    .type-image{
        @extend %molecule-images-rte;        
    }

    /* Listes */
    ul:not(.unstyled){ 
        @extend %atom-ul-rte;
        // clear: both;
    }
    ol:not(.unstyled){
        @extend %atom-ol-rte;
        // clear: both;
    }
    // Table
    .table-content{
        @extend %molecule-table--responsive;
        clear: both;
        margin-bottom: $margin-item-rte;
    }   

    /* MEDIAS */
    .media{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Quote
    .quote{
        clear: both;
        & > *:last-child{
            margin-bottom: 0;
        }
        margin-bottom: $margin-item-rte;
    }

    // Highlight Content
    .highlight-content{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    //  Slider Int
    .slider-int{
        clear: both;
        margin-bottom: $margin-item-rte;
    }
    

    // Trombinoscope
    .grid-trombinoscope{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Groupe de liens
    .link-group{
        clear: both;
        margin-bottom: $margin-item-rte;
    }
    // Pas de marge sur le dernier élément
    & > *:last-child{
        // margin-bottom: 0;
    }
}