%atom-ul-rte{
    padding-left: 60px;
    margin-bottom: $margin-p;
    list-style: none;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: 0px;
        position: relative;
        font-weight: 400;
        &:before{
            content: '';
            height: 5px;
            width: 5px;
            background-color: $core-color;
            border-radius: 500px;
            margin-right: 6px;
            display: block;
            position: absolute;
            left: -15px;
            top: 14px;
        }
    }
    ul{        
        padding-left: 90px;
        @include RWD(mobile){
            padding-left: 40px;
        }
    }
}

%atom-ol-rte{
    padding-left: 50px;
    list-style: none;
    counter-reset: item;
    margin-bottom: $margin-p;

    @include RWD(mobile){
        padding-left: 30px;
    }
    li{
        margin-bottom: 0;
        position: relative;
        display: flex;
        align-items: flex-start;
        &:before{
            counter-increment: item;
            content: counters(item, '.') ". ";
            color: $core-color;
            display: block;
            top: 0px;
            font-weight: 700;
            margin-right: 5px;
            margin-top: 1px;
        }
    }
    ol{
        padding-left: 90px;
        @include RWD(mobile){
            padding-left: 40px;
        }
    }
}