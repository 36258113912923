.layout-demarche{
    $this: &;
    padding: 30px 0;

    &__title{
        font-weight: 700;
        font-size: 2.5rem;
        font-family: $fancy-font;
        color: $core-color;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 40px;
        line-height: 1.2em;

        &:before{
            display: block;
            content: '';
            width: 53px;
            height: 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto;
            margin-bottom: 10px;
            background-image: url( svg-bg-uri($svg-wave, $second-color) );

            @include RWD(mobile){
                width: 43px;
                height: 15px;
            }
        }
        @include RWD(mobile){
            font-size: 2rem;
            margin-bottom: 20px;
            &:before{
                margin-bottom: 5px;
            }
        }
    }
    &__description{
        font-size: 2.0rem;
        line-height: 1.2em;
        color: #5c5c5c;
        margin-bottom: 25px;

        @include RWD(mobile){
            font-size: 1.6rem;
        }
    }
    &--highlighted{
        background-color: $second-color;
        border-radius: 7px;
        padding: 50px;
        @include RWD(tablet){
            padding: 30px;
        }
        @include RWD(mobile){
            padding: 20px;
        }
        #{$this}__title{
            &:before{
                background-image: url( svg-bg-uri($svg-wave, #FFFFFF) );
            }
        }
        #{$this}__description{
            color: $core-color;
        }
    }
    &--serial{
        .layout-flex{
            display: flex;
            justify-content: space-between;

            @include RWD(mobile){
                display: block;
            }
        }
        .layout-left{
            width: 210px;
            flex-shrink: 0;

            @include RWD(mobile){
                width: 100%;
            }
        }
        .layout-right{
            width: 605px;

            @include RWD(tablet){
                width: 450px;
            }
            @include RWD(mobile){
                width: 100%;
            }
        }
        .layout-demarche__title{
            text-align: left;
            &:before{
                margin-left: 0;
            }
            @include RWD(mobile){
                text-align: center;
                &:before{
                    margin-left: auto;
                }
            }
        }
        .form-group{
            margin-bottom: 0;
            @include RWD(desktop){
                display: flex;
                .form-field{
                    flex: 1;
                }
                .form-field + .form-actions{
                    margin-left: 20px;
                }                
            }
            @include RWD(tablet){
                .form-actions{
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
            }
        }
    }
}

// SBS
.layout-demarche-twins{
    display: flex;
    justify-content: space-between;
    border-top: solid 2px #f6f6f6;
    border-bottom: solid 2px #f6f6f6;

    .layout-demarche{
        width: 440px;
        max-width: 100%;
        padding: 30px 0;
    }
    @include RWD(tablet){
        display: block;

        .layout-demarche{
            width: 100%;
            margin: 0 auto;
        }
    }
}