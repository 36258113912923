#splashscreen{
    position: fixed;
    top: 0; left: 0; width: 100%; height: 100%;
    background: rgba($second-color, 0.95);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    z-index: 300;

    .text{
        position: absolute;
        top: 50%; left: 50%; 
        transform: translate(-50%, -50%);
    }
    .close{
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-cross, #FFFFFF) );
        position: absolute;
        top: 20px; right: 20px;
        @include RWD(mobile){
            background-size: 20px;
        }
    }
    .text{
        display: block;
        width: 80%;
        text-align: center;
    }
    svg{
        margin-bottom: 10px;
        max-width: 100%;
    }
    .title{
        font-family: $montserrat;
        font-weight: 700;
        color: #FFFFFF;
        font-size: 4.0rem;
        text-transform: uppercase;
        margin-bottom: 25px;

        &:before{
            content: '';
            width: 50px;
            height: 20px;
            background-size: 50px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-wave, #a3efe6) );
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        @include RWD(mobile){
            font-size: 2rem;
            margin-bottom: 10px;
        }
    }
    .lead{
        font-family: $opensans;
        font-weight: 500;
        font-size: 2.0rem;
        color: #FFFFFF;
        margin-bottom: 40px;
        line-height: 31px;
        @include RWD(mobile){
            line-height: 1.2;
            font-size: 1.4rem;
            margin-bottom: 20px;
        }
    }
}