.mseu footer{
    @extend %organism-footer;
}
// Spé hp
.mseu .front footer{
    // padding-top: 190px;
    // margin-top: -160px; 
    @include RWD(mobile){
        padding-top: 120px;
        margin-top: -120px;
    }
}