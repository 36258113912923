%organism-event-highlight{
    @extend %atom-grid--list01;
    position: relative;
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: -100vw; 
        right: -100vw;
        top: -0px;
        bottom: -40px;
        height: auto; width: 300vw;
        background-color: $second-color;
        z-index: 1;
        @include RWD(tablet){
            left: -40px;
            right: -40px;
            width: auto;
        }
        @include RWD(mobile){
            left: -20px;
            right: -20px;
        }
    }
    & > * {
        position: relative;
        z-index: 2;
    }
    .item-right{
        flex: 1;
    }
    .item-visu{
        width: 330px;
        max-width: 100%;
    }
    .item-content{
        flex: 1;
        &:hover, &:focus{
            .item-title{
                text-decoration: none;
            }
        }
        .item-coup-de-coeur{
            color: $core-color;
        }
        .item-dates{
            & > *{
                color: $core-color;
            }
        }
        .dates-list{
            transition: height 0.5s;
            overflow: hidden;
        }
        .line-left{
            text-align: left;
        }
        .see-more{
            &.opened{
                .btn-arrow{
                    transform: rotate(-90deg);
                }
            }
            .btn-arrow{ 
                transform: rotate(90deg);
            }
            &:hover, &:focus{
                text-decoration: underline;
            }
        }
        .item-date{
            color: $core-color;
        }
        .item-title{
            color: $core-color;
        }
        .item-categories{
            color: $core-color;
            &:before{
                background-image: url( svg-bg-uri($svg-label, $core-color) );
            }
        }
    }
    .item-infos{
        &:before{
            background-color: #FFFFFF;
            height: 100%;
        }
        .item-geoloc{
            align-items: flex-start;
            color: $core-color;
            height: auto;
            padding-bottom: 15px;
            strong{
                
                font-family: $site-font;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 1.4rem;
            }
            &:before{
                background-image: url( svg-bg-uri($svg-marker, $core-color) );
            }
            &:after{
                background-color: #FFFFFF;
            }
        }
        .item-misc{
            color: $core-color;
            max-height: 150px;
            &:before{
                background-image: url( svg-bg-uri($svg-plus, $core-color) );
            }
            span:after{
                background-color: $core-color;
            }
        }
    }
}