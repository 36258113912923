.demarche-topic{
    display: block;

    .demarche-topic__visu{
        position: relative;
        text-decoration: none;
        padding-bottom: calc(73% + 1px); // +1px to avoid semi pixel on chrome
        display: block;
        overflow: hidden;
        @include RWD(mobile){
            padding-bottom: 65%;
        }
        &:after{
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 31%,rgba(0,0,0,0.04) 37%,rgba(0,0,0,0.24) 57%,rgba(0,0,0,0.59) 85%,rgba(0,0,0,0.7) 100%);
            content: '';
            position: absolute;
            top: 0; left: 0; width: 100%; height: 100%;
        }

        &:hover, &:focus{
            .demarche-topic__background{
                transform: scale(1.2);
            }
            .demarche-topic__title{
                transform: translateY(-30px);
            }
        }
    }
    .demarche-topic__background{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $core-color;
        transition: all, 0.5s;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
    }
    .demarche-topic__title{
        transition: all, 0.5s;
        position: absolute;
        bottom: 30px;
        left: 30px;
        width: calc(100% - 60px);
        font-family: $site-font;
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 2;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        z-index: 2;
        @include RWD(mobile){
            line-height: 1.4;
        }
    }

    margin-bottom: 20px;
}