// Partagé entre home/une et home/
%molecule-home-slider{
    position: relative;
    left: 50%;
    transform: translateX(-50%) translateX(100px);
    width: 1400px;
    @include RWD(desktop){
        min-height: 500px;
    }
    @include RWD(tablet){
        width: 100%;
        transform: translateX(-50%);
    }
        .slider-overflow{
            overflow: hidden;
            padding: 10px;
            margin: -10px;
        }
        .slider{
            width: 1200px;
            @include RWD(tablet){
                width: 80%;
                margin: 0 auto;
            }

            .owl-stage-outer{
                overflow: visible !important;
            }

            // Taille et couleur des slides
            .owl-item{
                transition: opacity 1s 0.3s;
                &.active{
                    .item{
                        opacity: 1;
                    }
                }
                .page{
                    width: 100%;
                    &:not(.big){
                        @include RWD(desktop){
                            @include grid-distribution(4, 20, 'item');
                        }                    
                        @include RWD(tablet){
                            @include grid-distribution(2, 20, 'item');
                        }
                        @include RWD(mobile){
                            @include grid-distribution(1, 0, 'item');
                        }
                    }
                    &.big{
                        @include RWD(desktop){
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .item{
                                &.big{
                                    width: calc(50% - ( (60px) / 6));
                                }
                                width: calc(25% - ( (60px) / 4));
                            }                            
                        }         
                        @include RWD(tablet){
                            @include grid-distribution(2, 20, 'item');
                        }
                        @include RWD(mobile){
                            @include grid-distribution(1, 0, 'item');
                        }
                    }
                }
            }

            // Animate Out
            @for $i from 1 through 8{
                $delay: $i * 0.05;
                .owl-item.active{
                    .item:nth-child(#{$i}){
                        transition: transform 0.3s #{$delay}s , opacity 0.3s #{$delay}s;
                    }
                }
            } 
            &.animate-out{
                .owl-item{
                    &:not(.active){
                        opacity: 0;
                        transition: opacity 0.3s;
                    }
                    &.active{
                        .item{
                            transform: translate(0, 40px);
                            opacity: 0;
                        }
                    }
                }
                @for $i from 1 through 8{
                    $delay: (8 - $i) * 0.05;
                    .owl-item.active{
                        .item:nth-child(#{$i}){
                            transition: transform 0.3s #{$delay}s , opacity 0.3s #{$delay}s;
                        }
                    }
                } 
            }
        }

        // NEXT/PREV ARROWS
        .owl-nav{
            z-index: 10;

            @include RWD(mobile){
                // display: none;
            }

            .owl-prev{
                @extend %atom-home-slider-arrow; // Flèches
                transform: rotate(180deg);
                left: -300px;
                width: 300px;
                outline: none;
                @include RWD(tablet){
                    width: 80px;
                    left: 0px;
                }
            }
            .owl-next{ 
                @extend %atom-home-slider-arrow; // Flèches
                right: 0;
                width: 200px;
                outline: none;
                @include RWD(tablet){
                    width: 80px;
                }
            } 

            &.disabled{
                display: none;
            }
        }
}