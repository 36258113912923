%molecule-home-section{
    padding: 45px 75px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(#203146, 0.15);
    position: relative;
    background-color: #FFFFFF;

    @include RWD(tablet){
        padding: 45px;
    }
    @include RWD(mobile){
        padding: 45px 20px;
    }

    .delete-wi{
        position: absolute;
        top: 45px;
        right: 75px;
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-filled-close, $second-color) );

        @include RWD(tablet){
            right: 45px;
        }
        @include RWD(mobile){
            right: 20px;
            top: 20px;
        }
        &:hover, &:focus{
            background-image: url( svg-bg-uri($svg-filled-close, $core-color) );
        }
    }

    h2{
        @extend %text-section-title-hp;
        color: $core-color;
        width: 100%;
        text-align: center;
        margin-bottom: 25px;

        &:before{
            display: block;
            content: '';
            width: 53px;
            height: 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 0 auto;
            margin-bottom: 20px;
            background-image: url( svg-bg-uri($svg-wave, $core-color) );

            @include RWD(mobile){
                width: 43px;
                height: 15px;
            }
        }
        @include RWD(mobile){
            font-size: 2.5rem;
            &:before{
                margin-bottom: 5px;
            }
        }
    }
}