%text-basis-rte{    
    font-family: $main-font;
    font-size: 1.6rem;
    line-height: 1.2em;
    color: $text-basis-color;
    font-weight: 400;

    @include RWD(mobile){
        font-size: 1.4rem;
    }
}

%text-caption-rte{
    font-family: $main-font;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 400;
}

%text-teaser-description{
    font-weight: 400;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: normal;
}