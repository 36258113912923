%atom-customSelect{
    height: 42px;
    .customSelect {
        padding: 7.5px 50px 7.5px 20px;;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        
        &.arrowed {
            &:after { 
                position: absolute;
                top: 50%;
                right: 15px;
                content: '';
                display: block;
                height: 20px;
                width: 20px;
                background-size: 15px;
                background-position: center;
                background-repeat: no-repeat;
                transform:  translateY(-50%) rotate(90deg);
            }
        }
    }
    option{
        padding: 10px;
        font-family: $montserrat;
        font-weight: 400;
        font-size: 1.4rem;
        color: #7e7e7e;
    }
    .customSelectInner{
        font-family: $montserrat;
        font-weight: 400;
        font-size: 1.4rem;
        color: #7e7e7e;
        text-transform: uppercase;  
    }

    &--grey{
        @extend %atom-customSelect;
    }
    &--white{
        @extend %atom-customSelect;
        .customSelect{
            background-color: #FFFFFF;
            border: none;
            border-radius: 0;
            &.arrowed:after{
                background-image: url( svg-bg-uri($svg-arrow1, #7e7e7e) );
            }
        }
    }
    &--custom{
        @extend %atom-customSelect;
        height: 50px;
        .customSelect{
            background-color: #F6F6F6;
            border: solid 2px transparent;
            border-radius: 0;

            &.arrowed:after{
                background-image: url( svg-bg-uri($svg-arrow1, #7e7e7e) );
            } 
        }
        .customSelectInner{
            color: #7e7e7e;
        }
        .customSelectContain.focused .customSelect{
            border: solid 1px $core-color;
        }
        .customSelectContain select{
            background-color: #FFFFFF;
            color: #000000;
        }
        &.focused{
            .customSelect{
                border-color: $core-color;
            }
        }
    }
} 