%molecule-form-components{
    ::-webkit-input-placeholder {
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    }
    :-moz-placeholder{ 
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    } 
    ::-moz-placeholder { 
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    }
    :-ms-input-placeholder {  
        font-family: $site-font;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #7e7e7e;
    }

    .text-info{
        margin-bottom: $margin-p;
        line-height: 1.2em;
    }
    
    input:not([type="radio"]):not([type="checkbox"]):not([type="file"]):not(.webform-calendar){ 
        @extend %atom-form-input;

        &[type="file"]{
            border: none;
            padding: 0; 
            margin-right: 20px;
        }
        &.webform-calendar{
            width: auto;
        } 
    } 
    input[type="file"]{
        display: flex;
        align-items: center;
    }
    label:not(.option){
         @extend %text-form-label;
         color: $core-color;
         position: relative;
         line-height: 1.2em;

         .required{
             color: $error;
         }
     }
    .RadiobuttonsWidget{
        input[type="radio"]{
            @extend %atom-form-radio;
        }
        .content label + label{
            margin-top: 5px;
        }
    }
    .CheckboxesWidget{
        input[type="checkbox"]{
            @extend %atom-form-checkbox;
        }
        .content li + li{
            margin-top: 5px;
        }
    }
    .hint, .comment-field{
        font-size: 1.6rem;
        font-family: $main-font;
        font-style: italic;
        margin-top: 5px;
    }
    .content label{
        font-weight: 400;
        text-transform: none;
    }
    .CheckboxWidget.widget{
        .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            input{
                margin-top: 0;
                margin-right: 15px;
            }
        }
    }
    p{
        margin-bottom: 20px;
    }
    //  Textarea
    textarea{
        @extend %atom-form-input;
        @extend %text-form-input;
        padding: 15px;
        height: auto; 
        line-height: 1.2;
        display: block;
        resize: vertical;
    }
    //  SELECTS
    .customSelectContain{
        @extend %atom-customSelect--custom;
        
    }   
    // Multiple
    .select2{
        @extend %atom-select2--custom;
    }
} 

.generic-form{
    @extend %bone-form-structure;
    @extend %molecule-form-components;
}