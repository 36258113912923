// .ios, .ipad, .iphone, .ipod

// If input/select/textarea inside Fixed element /!\ conflict with edge: prevent page scrolling
// html, body{
// 	// -webkit-overflow-scrolling : touch !important;
//     overflow: auto !important;
//     // height: 100% !important;
// }

.mseu.ios #banner{
    background-attachment: scroll;
}
.mseu.ios{
    header .nav-top .menu-triggerBox .menu-panel .menu-rwd-content{
        height: calc(100% - 100px);        
    }
}
.mseu.ios{
    @include RWD(tablet){ //ipad iphone ipod
        .aroundme--fullscreen #aroundme{
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; 
        }        
    }
}
