$path: '../fonts/';

// MontSerrat
    // Regular
    @font-face {
        font-family: 'MontSerrat';
        src: url('#{$path}montserrat-regular-webfont.eot');
        src: url('#{$path}montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}montserrat-regular-webfont.woff2') format('woff2'),
             url('#{$path}montserrat-regular-webfont.woff') format('woff'),
             url('#{$path}montserrat-regular-webfont.ttf') format('truetype'),
             url('#{$path}montserrat-regular-webfont.otf') format('otf'),
             url('#{$path}montserrat-regular-webfont.svg#montserratregular') format('svg');
        font-weight: 400;
        font-style: normal;
    }
    // medium
    @font-face {
        font-family: 'Montserrat';
        src: url('#{$path}montserrat-medium-webfont.eot');
        src: url('#{$path}montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}montserrat-medium-webfont.woff2') format('woff2'),
             url('#{$path}montserrat-medium-webfont.woff') format('woff'),
             url('#{$path}montserrat-medium-webfont.ttf') format('truetype'),
             url('#{$path}montserrat-medium-webfont.otf') format('otf'),
             url('#{$path}montserrat-medium-webfont.svg#montserratmedium') format('svg');
        font-weight: 500;
        font-style: normal;
    }
    // semibold
    @font-face {
        font-family: 'Montserrat';
        src: url('#{$path}montserrat-semibold-webfont.eot');
        src: url('#{$path}montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}montserrat-semibold-webfont.woff2') format('woff2'),
             url('#{$path}montserrat-semibold-webfont.woff') format('woff'),
             url('#{$path}montserrat-semibold-webfont.ttf') format('truetype'),
             url('#{$path}montserrat-semibold-webfont.otf') format('otf'),
             url('#{$path}montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
        font-weight: 600;
        font-style: normal;
    }
    // Bold
    @font-face {
        font-family: 'MontSerrat';
        src: url('#{$path}montserrat-bold-webfont.eot');
        src: url('#{$path}montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}montserrat-bold-webfont.woff2') format('woff2'),
             url('#{$path}montserrat-bold-webfont.woff') format('woff'),
             url('#{$path}montserrat-bold-webfont.ttf') format('truetype'),
             url('#{$path}montserrat-bold-webfont.otf') format('otf'),
             url('#{$path}montserrat-bold-webfont.svg#montserratbold') format('svg');
        font-weight: 700;
        font-style: normal;
    }
    // ExtraBold
    @font-face {
        font-family: 'MontSerrat';
        src: url('#{$path}montserrat-extrabold-webfont.eot');
        src: url('#{$path}montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}montserrat-extrabold-webfont.woff2') format('woff2'),
             url('#{$path}montserrat-extrabold-webfont.woff') format('woff'),
             url('#{$path}montserrat-extrabold-webfont.ttf') format('truetype'),
             url('#{$path}montserrat-extrabold-webfont.otf') format('otf'),
             url('#{$path}montserrat-extrabold-webfont.svg#montserratextrabold') format('svg');
        font-weight: 800;
        font-style: normal;
    }

// OpenSans
    // Regular
    @font-face {
        font-family: 'OpenSans';
        src: url('#{$path}opensans-regular-webfont.eot');
        src: url('#{$path}opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}opensans-regular-webfont.woff2') format('woff2'),
             url('#{$path}opensans-regular-webfont.woff') format('woff'),
             url('#{$path}opensans-regular-webfont.ttf') format('truetype'),
             url('#{$path}opensans-regular-webfont.otf') format('otf'),
             url('#{$path}opensans-regular-webfont.svg#opensansregular') format('svg');
        font-weight: 400;
        font-style: normal;
    }
    // SemiBold
    @font-face {
        font-family: 'OpenSans';
        src: url('#{$path}opensans-semibold-webfont.eot');
        src: url('#{$path}opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}opensans-semibold-webfont.woff2') format('woff2'),
             url('#{$path}opensans-semibold-webfont.woff') format('woff'),
             url('#{$path}opensans-semibold-webfont.ttf') format('truetype'),
             url('#{$path}opensans-semibold-webfont.otf') format('otf'),
             url('#{$path}opensans-semibold-webfont.svg#opensanssemibold') format('svg');
        font-weight: 600;
        font-style: normal;
    }
    // Bold
    @font-face {
        font-family: 'OpenSans';
        src: url('#{$path}opensans-bold-webfont.eot');
        src: url('#{$path}opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
             url('#{$path}opensans-bold-webfont.woff2') format('woff2'),
             url('#{$path}opensans-bold-webfont.woff') format('woff'),
             url('#{$path}opensans-bold-webfont.ttf') format('truetype'),
             url('#{$path}opensans-bold-webfont.otf') format('otf'),
             url('#{$path}opensans-bold-webfont.svg#opensansbold') format('svg');
        font-weight: 700;
        font-style: normal;
    }