%organism-survey{    
    @extend %molecule-home-section;
    color: $core-color;
    background-color: $third-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:before{
        content: '';
        display: inline-block;
        width: 110px;
        height: 110px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-speech, #FFFFFF) );
        position: absolute;
        top: 50px;
        left: 80px;

        @include RWD(tablet){
            display: none;
        }
    }
    .delete-wi{
        background-image: url( svg-bg-uri($svg-filled-close, $core-color) );
    }
    h2:before{
        background-image: url( svg-bg-uri($svg-wave, #fccb6c) );
    }

    .survey{
        &-subtitle{
            font-size: 2.0rem;
            font-weight: 500;
            margin-bottom: 20px;
            font-family: $fancy-font;
        }
        &-form{
            .form-group{
                margin-bottom: 40px;
            }
            .form-field{
                display: flex;

                @include RWD(mobile){
                    display: block;
                }
            }
            .form-item{
                position: relative;
                & + .form-item{
                    margin-left: 20px;
                    @include RWD(mobile){
                        margin: 0;
                        margin-top: 10px;
                    }
                }
            }
            label.option{
                font-size: 2.0rem;
                color: $core-color;
                font-family: $main-font;
            }
            input[type="radio"]{
                @extend %atom-form-radio;
            }
        }
    }
}