%atom-form-input{
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    border: 2px solid #dddddd;
    border-radius: 0;
    @extend %text-form-input;
    padding: 0 20px; 
    transition: all, 0.5s;
    line-height: 50px;

    &:focus{
        border-color: $core-color;
        outline: none;
        background-color: #FFFFFF;
    }
    &::-webkit-input-placeholder {
        text-transform: uppercase;
        font-family: $fancy-font;
        font-size: 1.4rem;
        color: #7e7e7e;
    }
    &::-moz-placeholder{
        text-transform: uppercase;
        font-family: $fancy-font;
        font-size: 1.4rem;
        color: #7e7e7e;
    }
    &::-ms-input-placeholder {
        text-transform: uppercase;
        font-family: $fancy-font;
        font-size: 1.4rem;
        color: #7e7e7e;
    }
} 