%organism-slider-int{
    @extend %molecule-home-slider;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
    min-height: unset;

    // Override sizing et centrage 
    width: 1600px;
    transform: translateX(-50%);
    margin: 0 auto;
    @include RWD(tablet){
        max-width: 100%;
    }
    @include RWD(mobile){
        margin-bottom: 10px;
        width: calc(100% + 40px);
        max-width: unset;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        left: 0;
        transform: none;
    }
    .slider{
        margin: 0 auto;
    }
    .owl-nav{
        .owl-prev{
            left: -100px;
            @include RWD(tablet){
                left: 0;
            }
            @include RWD(mobile){
                // left: -20px;
            }
        }
        .owl-next{
            @include RWD(mobile){
                // right: -20px;
            }
        }
        .picto{            
            background-image: url( svg-bg-uri($svg-arrow1, $core-color) ) !important;
        }
    }
    .text{
        position: absolute;
        bottom: 0; right: 0; width: 100%;
        @extend %atom-caption-rte;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        .img-title{
            font-family: "MontSerrat", arial;
            font-weight: 700;
            font-size: 1.7rem;
            color: #ffffff;
            text-transform: uppercase;
            width: 100%;
            flex-shrink: 0;
            padding-right: 200px;
            @include RWD(mobile){
                padding-right: 0;
            }
        }
        .description{
            flex: 1 1 auto;
            @include RWD(mobile){
                display: none;
            }
        }
        .caption{
            flex: 1 0 auto;
            text-align: right;
            @include RWD(mobile){
                width: 100%;
                text-align: left;
            }
        }
    }
    .slider{
        // Taille et design des slides
        .owl-item{
            .item{
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
                opacity: 0.2;
                height: 620px;
                content: '';
                display: block;
                transition: opacity 1s;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @extend %atom-slider-une-vignette;
                @include RWD(tablet){
                    height: unset;
                    padding-bottom: 57%;
                }
            }
        }
    }
}