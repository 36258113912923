%atom-slider-arrow--mega{
    position: absolute;
    top: 0;
    transition: all, 0.5s;
    cursor: pointer;
    content: '';
    display: flex;
    align-items: center;
    width: 50px;
    height: 100%;
    z-index: 100;

    
    .picto{
        background-size: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-arrow1, #FFFFFF) );
        z-index: 10;
        height: 50px;
        width: 50px;
        background-color: $second-color;
        border-radius: 50%;
        position: relative;
        left: 30px;
        @include RWD(tablet){
            width: 35px;
            height: 35px;
            background-size: 20px;
        }
        @include RWD(mobile){
            left: 35px;
            width: 40px;
            height: 40px;
            background-size: 18px;
        }
    }

    .flexbox{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.owl-prev{
        transform: rotate(180deg);
    }

    &.disabled{
        pointer-events: none;
        opacity: 0 !important;
        @include RWD(mobile){
            display: none;
        }
    }

    &--grey{
        @extend %atom-slider-arrow--mega;
        background-color: #202020;
        path{
            fill: #FFFFFF;
            transition: all, 0.5s;
        }
        &:hover, &:focus{
            background-color: $core-color;
        }
    }
}