%organism-grid-carrefour{
    margin-bottom: 40px;
    // @include RWD(desktop){
    //    @include grid-distribution(3, 20, grid-item);
    // }
    // @include RWD(tablet_only){
    //    @include grid-distribution(2, 20, grid-item);
    //    display: block;
    // }
    // @include RWD(mobile){
    //    @include grid-distribution(1, 0, grid-item);
    //    display: block;
    // }
    .grid-item{
        @include RWD(desktop){            
            width: calc(33% - 10px);
        }
        @include RWD(tablet_only){
            width: calc(50% - 10px);
        }
        @include RWD(mobile){
            width: 100%;
        }
        @extend %atom-vignette-carrefour;
        margin-bottom: 20px;
    }
}