.block-custom-container-breadcrumb{
    .back{
        display: none;
        font-family: $site-font;
        font-size: 1.2rem;
        color: #858585;

        &:hover, &:focus{
            text-decoration: underline;
        }

        &:before{
            content: '< ';
        }
        @include RWD(mobile){
            display: block;
        }
    }
    .page-depth{
        @extend %molecule-ariane;
    }
    margin-bottom: 20px;
}