%molecule-inlines-rte{
    @extend %text-basis-rte;

    strong{
        font-weight: 600;
        color: #505050;
    }
    em{
        font-style: italic;
        color: #505050;
    }
    sup{
        vertical-align: super;
        font-size: smaller;
        line-height: normal; 
    }
    sub{
        vertical-align: sub;
        font-size: smaller;
        line-height: normal;
    }
    a{
        @extend %atom-link-rte;

        &.btn-square--filled--second{
            text-decoration: none;
        }
        &.external:not(.wi-link-group){
            &:after{
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background-size: 10px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-external, $core-color) );
                margin-left: 5px;
            }
        }
    }
}