.mseu .no-js{
    @keyframes loaderNoJS{
        0%{
            left: 0;
            height: 100%;
            width: 100%;
        }
        99%{
            left: 0;
            height: 100%;
            width: 100%;
        }
        100%{
            left: -9999999px;
            height: 0;
            width: 0;
            visibility: hidden;
        }
    }
    @keyframes bannerNoJS{
         0%{
            opacity: 0;
            transform: translateY(50px);
        }
        99%{
            opacity: 0;
            transform: translateY(50px);
        }
        100%{
            opacity: 1;
            transform: translateY(0);
        }
    }
    #loader{
        animation: loaderNoJS 3s;
        animation-fill-mode: forwards;
    }
    &.front #banner h1,
    &.front #banner .banner-form,
    &.front #banner .quicklinks .quicklink{
        animation: bannerNoJS 3s;
        animation-fill-mode: forwards;
    }
    &.front #une .slider-une-container,
    &.front #une .filters,
    &.front #socials .slider-social-container,
    &.front #socials .filters{
        display: none;
    }
    #footer{
        padding-top: 50px;
        margin-top: 0;
    }
    .no-js-links{
        display: flex !important;
        justify-content: center;
        a{
            font-family: $montserrat;
            @extend %atom-btn-round--core;
            font-size: 1.4rem;
            font-weight: 800;
            color: #FFFFFF;
            margin: 0 20px;
            &:hover, &:focus{
                background-color: $second-color !important;
            }
        }
        padding-bottom: 50px;
    }
    .wi-slideNpop{
        opacity: 0;
    }
    .collapsing-box{
        display: block !important;
    }
}