#grid--list01{
    @extend %molecule-grid--list01;
}  
.view-activity{
    #grid--list01{
        .grid-item{
            flex-wrap: wrap;
            .item-right{
                flex: 1;
                @include RWD(tablet){
                    display: flex;
                    flex-flow: column-reverse;
                }
            }
            .item-bottom{
                width: calc(100% + 80px);
                padding: 40px;
                background-color: #f6f6f6;
                flex-shrink: 0;
                margin-left: -40px;
                margin-right: -40px;
                margin-top: 40px;
                display: none;
            }
            .item-content{
                .item-title{
                    margin-bottom: 0;
                }
                .item-description{
                    font-size: 1.8rem;
                    color: #5c5c5c;
                }
                &:hover, &:focus{
                    .item-title{
                        text-decoration: none;
                    }
                }
                .item-link{
                    text-decoration: none;
                    margin-bottom: 10px;
                    display: block;
                    &:hover, &:focus{
                        .item-title{
                            text-decoration: underline;
                        }
                    }
                }
                .btn-line{
                    display: flex;
                    flex-wrap: wrap;
                    @include RWD(mobile){
                        display: block;
                    }
                    button{
                        margin-bottom: 10px;
                        @include RWD(mobile){
                            margin-bottom: 0;
                        }
                        margin-right: 10px;
                    }
                    button + button{
                        // margin-left: 10px;
                        @include RWD(mobile){
                            margin: 0;
                            margin-top: 10px;
                        }
                    }
                    .btn-arrow{
                        transform: rotate(90deg);
                    }
                    button.opened{
                        .btn-arrow{
                            transform: rotate(-90deg);
                        }
                        &:before{
                            height: 100%;
                        }
                        .btn-text{
                            color: $second-color;
                        }
                        .btn-arrow{
                            background-image: url( svg-bg-uri($svg-arrow1, $second-color) );
                        }
                    }
                }
            }
            .item-infos{
                @include RWD(mobile){
                    margin-bottom: 20px;
                }
                .item-geoloc,
                .item-misc{
                    align-items: flex-start;
                    font-weight: 400;
                    text-transform: none;
                    strong{
                        display: block;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
                .item-geoloc{
                    &:before{
                        height: 24px;
                        background-size: 15px;
                        background-image: url( svg-bg-uri($svg-label, #2da192) );
                        transform: rotate(90deg);
                    }
                }
                .item-misc{
                    span:after{
                        display: none !important;
                    }
                    &:before{
                        width: 21px;
                        height: 25px;
                        background-size: 15px;
                        background-image: url( svg-bg-uri($svg-people, $core-color) );
                    }
                }
            }
        }
    }
}