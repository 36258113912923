%atom-btn-round{
    padding: 12px 20px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    // border-style: solid;
    // border-width: 1px;
    // border-color: transparent;
    transition: all, 0.5s;
    overflow: hidden;
    border-radius: 10px;

    
    .btn-text{
        font-family: $montserrat;
        font-size: 1.4rem;
        text-transform: uppercase; 
        font-weight: 400;
        letter-spacing: 0px;
        transition: all, 0.3s;
        line-height: 1;
        padding-top: 3px;
        display: block;
    }
    &--core{
        @extend %atom-btn-round;
        background-color: $core-color;
        border-color: $core-color;
        .btn-text{
            color: #FFFFFF;
        }
        &:not(.actif){
            &:hover, &:focus{
                background-color: #FFFFFF;
                .btn-text{ 
                    color: $core-color;
                }
            }
        }
    }
    &--inverted{
        @extend %atom-btn-round;
        background-color: $second-color;
        border-color: $second-color;
        .btn-text{
            color: $core-color;
        }
        &:not(.actif){
            &:hover, &:focus{
                background-color: #FFFFFF;
                .btn-text{ 
                    color: $second-color;
                }
            }
        }
    }
    &--grey{ 
        @extend %atom-btn-round;
        background-color: #ffffff;
        border-color: #ffffff;
        .btn-text{
            color: #5c5c5c;
        }
        &:not(.actif){
            &:hover, &:focus{
                background-color: #5c5c5c;
                .btn-text{ 
                    color: #ffffff;
                }
            }
        }
    }
}

.btn-round{
    &.core{
        @extend %atom-btn-round--core;
    } 
    &.inverted{ 
        @extend %atom-btn-round--inverted;
    }
    &.grey{
        @extend %atom-btn-round--grey;
    }
}
//~#BUTTONS
.btn-round--core{
    @extend %atom-btn-round--core;
}
.btn-round--inverted{
    @extend %atom-btn-round--inverted;
}
.btn-round--grey{
    @extend %atom-btn-round--grey;
}
//#BUTTONS