.favoris-teaser{
    @include RWD(tablet){
        &:nth-child(1),
        &:nth-child(2){
            margin-bottom: 50px;
        }
    }
    @include RWD(mobile){
        margin-bottom: 50px;
        &:last-child{
            margin-bottom: 0 !important;
        }
    }
    padding-bottom: 60px;
    position: relative;
    &:after{
        content: '';
        display: block;
        background-color: #f6f6f6;
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        height: 2px;
    }
    &__link{
        text-decoration: none;
        display: block;
        &:hover, &:focus{
            text-decoration: none;
            .favoris-teaser__title{
                text-decoration: underline;
            }
        }
    }

   &__type{
        font-family: $fancy-font;
        font-weight: 400;
        font-size: 1.4rem;
        color: #7e7e7e;
        text-transform: uppercase;   
        margin-bottom: 10px;        
   }
   &__content{
       padding-left: 40px;
   }
   &__tag{
        font-family: $fancy-font;
        font-weight: 400;
        font-size: 1.4rem;
        color: #2da192;
        text-transform: uppercase;
        margin-bottom: 2px;  
        transform: translateX(-38px);
        display: flex;
        align-items: center;
        
        &:before{
            content: '';
            display: inline-block;
            width: 13px;
            height: 15px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-bookmark, $core-color) );
            margin-right: 25px;
            transform:  translateY(2px);
        }
   }
   &__title{
       font-family: $fancy-font;
       font-weight: 500;
       font-size: 2.2rem;
       color: $core-color;
       text-transform: uppercase;
       margin-bottom: 5px;    
       line-height: 1.2em;    
   }
   &__description{
       font-family: $main-font;
       font-weight: 600;
       color: #7e7e7e;
       font-size: 1.4rem;
       margin-bottom: 3px;        
   }
   &__date{
       font-family: $fancy-font;
       font-weight: 600;
       font-size: 2.0rem;
       color: #2da192;
   }
   &__crowding{
       display: flex;
       align-items: center;
       margin-top: 15px;

        &-amount{
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            color: #FFFFFF;
            font-size: 2.1rem;
            height: 50px;
            width: 50px;
            flex-shrink: 0;
            &--orange{
                background-color: $crowded-orange;
            }
            &--green{
                background-color: $crowded-green;
            }
            &--grey{
                background-color: $crowded-grey;
            }
            &--white{
                background-color: $crowded-white;
                color: #272222;
            }
       }
       &-label{
           flex-shrink: 0;
           font-size: 1.5rem;
           color: $core-color;
           flex: 1;
       }
       &-amount + &-label{
           margin-left: 10px;
       }
   }
   &__trash{
       font-weight: 600;
       font-size: 1.4rem;
       color: $core-color;
       display: flex;
       align-items: center;
       position: absolute;
       bottom: 0;
       left: 0;

       &:before{
           content: '';
           display: block;
           width: 20px;
           height: 20px;
           background-size: contain;
           background-position: center;
           background-repeat: no-repeat;
           background-image: url( svg-bg-uri($svg-trash, $core-color) );
           margin-right: 10px;
       }

       &:hover, &:focus{
           text-decoration: underline;
       }
   }
}